import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { RtkqTagEnum } from "common/Constants";
import {
  nimbleX360Api,
  invalidatesTags,
  providesTags,
} from "common/StoreQuerySlice";
import { downloadFile } from "common/Utils";
import { objectToFormData } from "utils/ObjectUtils";

const BANK_SCHEDULE_BASE_URL = "/banks/schedule";
const REMITA_SCHEDULE_BASE_URL = `https://api.bravewood.com.ng`
export const BankScheduleApi = nimbleX360Api.injectEndpoints({
  endpoints: (builder) => ({
    getBankSchedules: builder.query({
      query: (config) => ({
        url: `${BANK_SCHEDULE_BASE_URL}`,
        ...config,
      }),
      providesTags: (data, error) =>
        !error ? providesTags(data?.pageItems, RtkqTagEnum.BANK_SCHEDULE) : [],
    }),
    getBankSchedule: builder.query({
      query: ({ path, ...config }) => ({
        url: `${BANK_SCHEDULE_BASE_URL}/${path?.id}`,
        ...config,
      }),
      providesTags: (data, error) =>
        !error ? providesTags(data?.pageItems, RtkqTagEnum.BANK_SCHEDULE) : [],
    }),
    updateBankSchedule: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${BANK_SCHEDULE_BASE_URL}/${path?.id}`,
        ...config,
      }),
      invalidatesTags: (_, error) =>
        !error ? invalidatesTags(RtkqTagEnum.BANK_SCHEDULE) : [],
    }),
    updateBankScheduleStatus: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${BANK_SCHEDULE_BASE_URL}/${path?.id}/status`,
        method: "put",
        ...config,
      }),
      invalidatesTags: (_, error) =>
        !error ? invalidatesTags(RtkqTagEnum.BANK_SCHEDULE) : [],
    }),
    downloadBankScheduleTemplate: builder.mutation({
      query: (config) => ({
        url: `${BANK_SCHEDULE_BASE_URL}/downloadtemplate`,
        responseType: "blob",
        ...config,
      }),
      onQueryStarted: async (config, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          downloadFile(data, config.data.fileName + ".xls");
        } catch (error) {
          console.log(error);
        }
      },
    }),
    uploadBankSchedule: builder.mutation({
      query: (config) => ({
        url: `${BANK_SCHEDULE_BASE_URL}/uploadtemplate`,
        method: "post",
        ...config,
        data: objectToFormData(config.data),
      }),
      invalidatesTags: (_, error) =>
        !error ? invalidatesTags(RtkqTagEnum.BANK_SCHEDULE) : [],
    }),
    downloadBankScheduleReport: builder.mutation({
      query: (config) => ({
        url: `${BANK_SCHEDULE_BASE_URL}/generate`,
        responseType: "blob",
        ...config,
      }),
      onQueryStarted: async (config, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          downloadFile(data, config.data.fileName + ".xls");
        } catch (error) {
          console.log(error);
        }
      },
    }),
    uploadBankScheduleReport: builder.mutation({
      query: (config) => ({
        url: `${BANK_SCHEDULE_BASE_URL}/uploadtemplate/assign-officer`,
        method: "post",
        ...config,
        data: objectToFormData(config.data),
      }),
      invalidatesTags: (_, error) =>
        !error ? invalidatesTags(RtkqTagEnum.BANK_SCHEDULE) : [],
    }),

    // resolveRemita: builder.mutation({
    //   query: () => ({
    //     url: `${REMITA_SCHEDULE_BASE_URL}/remita/upload/`,
    //     // ...config,
    //     // method: "get",
    //   }),

    // }),
  }),
});

export const apiIntegration = createApi({
  reducerPath: "remita",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://api.bravewood.com.ng/api`,
  }),
  endpoints: (builder) => ({
    resolveRemita: builder.mutation({
      query: () => ({
        url: `${REMITA_SCHEDULE_BASE_URL}/remita/upload/?page=1`,
        // ...config,
        // method: "get",
      }),

    }),
    resolveRemitaUpload: builder.mutation({
      query: (data) => ({
        url: `${REMITA_SCHEDULE_BASE_URL}/remita/upload/`,
        // ...config,
        method: "post",
        body:objectToFormData(data.data)
      }),

    }),
    resolveRemitaStartOne: builder.mutation({
      query: (data) => ({
        url: `${REMITA_SCHEDULE_BASE_URL}/remita/start_single_schedule/${data}/`,
        // ...config,
        method: "post",
        // body:objectToFormData(data.data)
      }),

    }),
    resolveRemitaStartBulk: builder.mutation({
      query: (data) => ({
        url: `${REMITA_SCHEDULE_BASE_URL}/remita/start_bulk_schedule/${data}/`,
        // ...config,
        method: "post",
        body:objectToFormData(data.data)
      }),

    }),
    resolveRemitaStopSigle: builder.mutation({
      query: (data) => ({
        url: `${REMITA_SCHEDULE_BASE_URL}/remita/stop_single_schedule/${data}/`,
        // ...config,
        method: "post",
        body:objectToFormData(data.data)
      }),

    }),
    resolveRemitaStopBulk: builder.mutation({
      query: (data) => ({
        url: `${REMITA_SCHEDULE_BASE_URL}/remita/stop_bulk_schedule/${data}/`,
        // ...config,
        method: "post",
        body:objectToFormData(data.data)
      }),

    }),
    resolveRemitaList: builder.mutation({
      query: (id, config) => ({
        url: `${REMITA_SCHEDULE_BASE_URL}/remita/shedule/${id}/`,
        ...config,
        
        // method: "get",
      }),

    }),

    getHistory: builder.mutation({
      query: (id) => ({
        url: `${REMITA_SCHEDULE_BASE_URL}/remita/history/${id}/`,
        
        // method: "get",
      }),

    }),
    getMandateHistory: builder.mutation({
      query: (data) => ({
        url: `${REMITA_SCHEDULE_BASE_URL}/remita/mandate_repayment_history/`,
        method: "post",
        body:objectToFormData(data)
      }),

    }),

  }),
});
